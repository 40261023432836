import React from 'react';

// styles
import './dataMethodologyPopup.scss';

const DataMethodologyPopup = () => {
  const handleClose = (e) => {
    window.document.body.classList.remove('dataMethodologyPopup-open');

    const scrollY = window.document.body.style.top;
    window.document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);

    var popup = document.querySelector('.dataMethodologyPopup');
    var backdrop = document.querySelector('.dataMethodologyPopup-backdrop');
    if (backdrop) {
      backdrop.style.display = 'none';
    }
    if (popup) {
      popup.style.display = 'none';
    }
  };

  return (
    <>
      <div className="dataMethodologyPopup">
        <div className="dataMethodologyPopup-inner">
          <div className="dataMethodologyPopup-content">
            <a href="javascript:void(0);" onClick={handleClose} className="dataMethodologyPopup-close desktop">
              <div className="dataMethodologyPopup-close-outer">
                <div className="dataMethodologyPopup-close-inner"></div>
              </div>
            </a>
            <div className="dataMethodologyPopup-title">Wie lässt sich die Einkommensverteilung genau messen?</div>
            <div className="dataMethodologyPopup-text-wrapper">
              <div className="dataMethodologyPopup-text">
                <strong>DATEN</strong>
                <br />
                Die Swiss Inequality Database (SID) stützt sich auf die offiziellen Bundes-, Kantons-, Gemeinde- und Kirchensteuerdaten. Die
                Steuerdaten haben zwei grosse Vorteile. Erstens erlauben sie eine langfristige Betrachtung, da Daten zum steuerbaren Einkommen in der
                Schweiz bis ins Jahr 1917 zurückreichen. Zweitens ermöglichen Steuerstatistiken eine präzise und konsistente Analyse der obersten
                Einkommen, da sämtliche Steuerzahler berücksichtigt werden («Vollerhebung»). Dies im Unterschied zu Umfragen, in denen Steuerzahler
                mit hohen Einkommen oftmals nicht vertreten und die Angaben deshalb weniger verlässlich sind.
                <br />
                <br />
                <strong>METHODE</strong>
                <br />
                Die Eidgenössische Steuerverwaltung (ESTV) veröffentlicht jährlich Steuerdaten, die in verschiedene Einkommensklassen unterteilt
                werden. Zu den Einkommen zählen alle steuerbaren Einkünfte, dazu gehören Arbeits- wie auch Kapitaleinkünfte. Das zugrundeliegende
                Einkommenskonzept ist das Reineinkommen pro Haushalt gemäss Definition in der Bundessteuerstatistik.* Dieses weicht geringfügig von
                der Definition gemäss Art. 25 DBG und Art. 9 Abs. 1 StHG ab und beinhaltet das steuerbare Einkommen zuzüglich des Kinderabzugs,
                Zweitverdienerabzugs, Verheiratetenabzugs und Versicherungsabzugs.
                <br />
                <br />
                Die Verwendung dieses Konzepts erlaubt die internationale Vergleichbarkeit der Daten. Wie die Einkommen innerhalb der verschiedenen
                Klassen genau verteilt sind, ist in den öffentlich zugänglichen Daten nicht ersichtlich. Um daraus den Anteil der Top-Einkommen
                präzise ableiten zu können, müssen Annahmen zur Einkommensverteilung getroffen werden. Zahlreiche empirische Studien¹ zeigen, dass die
                Spitzeneinkommen einer bestimmten Verteilung (der sogenannten Pareto-Verteilung, benannt nach dem italienischen Ökonomen Vilfredo
                Pareto) folgen. Mit dieser international etablierten Methode lassen sich die Anteile der Top-Einkommen sehr genau bestimmen. Die
                Anteile der tieferen Einkommen können damit hingegen nicht beschrieben werden, weil diese Verteilungsannahme lediglich ab den obersten
                20 Prozent greift. Deshalb beschränkt sich die Swiss Inequality Database in ihren Auswertungen auch auf die obersten 20 Prozent der
                Einkommensbezüger.
                <br />
                <br />
                Die Einkommensanteile nach Steuern werden ebenfalls auf der Grundlage der publizierten Bundes-, Kantons-, Gemeinde- und
                Kirchensteuerdaten ermittelt, wobei dieselben Annahmen zur Anwendung kommen. Dabei gilt es zu beachten, dass die rein steuerliche
                Umverteilung berücksichtigt wird. So ist beispielsweise die umverteilende Wirkung der Alters- und Hinterlassenenversicherung (AHV)
                nicht enthalten. Für die Jahre ab 2019 werden die neuen verbesserten Steuerbelastungsstatistiken der ESTV verwendet. Auf unsere
                Kennzahlen hat dies lediglich minime Auswirkungen, sodass deren Vergleichbarkeit über die Zeit vollständig erhalten bleibt.
                <br />
                <br />
                Für die Jahre vor 1993 sind keine Daten der Eidgenössischen Steuerverwaltung (ESTV) zu den Steuerpflichtigen ohne Steueraufkommen
                verfügbar. Dieser Anteil wird durch einen Abgleich der Anzahl Steuersubjekte gemäss Eidgenössischer Steuerstatistik und der Anzahl
                potenzieller Steuerpflichtiger gemäss statistischem Jahrbuch der Schweiz ermittelt. Ab 1993 werden die verfügbaren ESTV-Daten zu den
                Steuerpflichtigen ohne Steueraufkommen verwendet. Dieser unvermeidliche Wechsel der Datengrundlage erklärt für manche Kantone den
                grossen Sprung im Anteil der Steuerpflichtigen ohne Steueraufkommen im Übergangsjahr 1993.
                <br />
                <br />
                Ab 2013 werden in den Steuerstatistiken auch die Stufen des Einkommens basierend auf dem Reineinkommen ausgewiesen. Für die Jahre
                davor basieren diese Stufen auf dem steuerbaren Einkommen. Dies hat Auswirkungen auf die von uns berechneten Einkommensschwellen in
                den Grafiken 4.1 und 4.2. So wird die Höhe der Einkommensschwellen bis und mit 2012 unterschätzt. Damit ist die Entwicklung der
                Schwellen zwischen 1917 und 2012 einerseits getrieben durch das Wirtschaftswachstum, andererseits aber auch durch die sich
                verändernden Steuerabzugsmöglichkeiten. Die Vergleichbarkeit innerhalb dieses Zeitraums ist vollständig gegeben. Dasselbe gilt für den
                Zeitraum ab 2013. Auf die Topeinkommensanteile und damit die restlichen Abbildungen hat dies jedoch vernachlässigbare Effekte.
                <br />
                <br />
                Die Daten der Swiss Inequality Database wurden unter Einhaltung gültiger wissenschaftlicher Standards verwendet. Basierend auf diesen
                Daten wurden bereits folgende wissenschaftliche Publikationen veröffentlicht:
                <ul>
                  <li>
                    Feld, L. P.; Frey, C.; Schaltegger, C.A. & Schmid, L.A. (2021). Fiscal federalism and income inequality: An empirical analysis for
                    Switzerland. Journal of Economic Behavior & Organization 185, S. 463-494.
                  </li>
                  <li>
                    Frey, C. & Schaltegger, C.A. (2021). The initiative, referendum, and distribution of income: An empirical analysis of Swiss
                    cantons. European Journal of Political Economy 66, S. 1-31.
                  </li>
                  <li>
                    Frey, C.; Gorgas, C. & Schaltegger, C.A. (2017). The long run effects of taxes and tax competition on top income shares: an
                    empirical investigation. Review of Income and Wealth 63(4), S. 792-820.
                  </li>
                  <li>
                    Frey, C. & Schaltegger, C.A. (2016). Progressive taxes and top income shares: A historical perspective on pre- and post-tax income
                    concentration in Switzerland. Economic Letters 148, S. 5-9.
                  </li>
                  <li>
                    Schaltegger, C.A.; Frey, C. & Häner, M. (2018). Zur Einkommensverteilung in der Schweiz. Wirtschaftswissenschaftliches Studium
                    47(11), S. 27-34.
                  </li>
                  <li>
                    Schaltegger, C.A.; Gorgas, C. (2011). The Evolution of Top Incomes in Switzerland over the 20th Century. Swiss Journal of
                    Economics and Statistics 147(4), S. 479-519.
                  </li>
                </ul>
                <br />
                Wir danken an dieser Stelle Dr. Christian Frey und Dr. Christoph Gorgas für die systematische Erstellung dieses wertvollen
                Datenschatzes.
                <br />
                <br />
                Falls Sie
                <ul>
                  <li>Anregungen oder Fragen zur Swiss Inequality Database haben,</li>
                  <li>die den Grafiken zugrundeliegenden Datensätze als Excel-Datei beziehen möchten</li>
                  <li>oder im Besitze von weiterführenden Datensätzen sind, die wir in die SID integrieren können,</li>
                </ul>
                <br />
                dann melden Sie sich bitte unter <a href="mailto:forschung@iwp.swiss">forschung@iwp.swiss</a>.
                <br />
                <br />
                *Massgebend für die Bestimmung der Haushalte ohne Steueraufkommen ist das steuerbare Einkommen. Unter einem Haushalt wird eine
                Steuereinheit verstanden. Konkubinatspaare gelten folglich als zwei separate Haushalte, verheirate Paare hingegen als ein gemeinsamer
                Haushalt. Dieser Umstand kann eine Verzerrung nach sich ziehen, falls die Heiratshäufigkeit zwischen den verschiedenen
                Einkommensgruppen variiert.²
                <br />
                <br />¹ Auswahl empirischer Studien zur Pareto-Verteilung:
                <ul>
                  <li>
                    Piketty, T. (2001) Les hauts revenus en France au XXe siècle. Inégalités et redistributions 1901– 1998, Bernard Grasset, Paris.
                  </li>
                  <li>
                    Dell, F., Piketty, T. and Saez, E. (2007) Income and Wealth Concentration in Switzerland over the Twentieth Century, in Top
                    Incomes Over the Twentieth Century: A Contrast Between Continental European and English-Speaking Countries. (Eds.) A. B. Atkinson
                    and T. Piketty, Oxford University Press, Oxford, chap. 11, pp. 472–500.
                  </li>
                  <li>Atkinson, A. B. and Piketty, T. (2010) Top Incomes: A Global Perspective, Oxford University Press, Oxford.</li>
                </ul>
                <br />² vgl. z.B. Auten, G. and Splinter, D. (2024). Income inequality in the United States: Using tax data to measure long-term
                trends. Journal of Political Economy (forthcoming).
              </div>
            </div>
            <div className="dataMethodologyPopup-footer">
              <a href="#" onClick={handleClose} className="dataMethodologyPopup-close d-md-none">
                <div className="dataMethodologyPopup-close-outer">
                  <div className="dataMethodologyPopup-close-inner"></div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="dataMethodologyPopup-backdrop"></div>
    </>
  );
};

export default DataMethodologyPopup;
